<template>
  <div class="intro">
    <van-nav-bar title="用户评价" >
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="content">
      <div class="tdc_content d_f"  v-for="(al,i) in als" :key="i">
        <div class="tdc_user">
          <img :src="al.headPortrait" alt="">
        </div>
        <div class="flex_1 tdc_c">
          <div class="tdc_c_title">{{al.nickname}}</div>
          <div class="d_f ali_c">
            <van-rate
              readonly
              v-model="al.starScore"
              void-icon="star-o"
              :size="12"
              color="#FF577B"
              allow-half
              void-color="#FF577B"
            />
            <div class="flex_1 t_r tdc_c_date">{{al.criticismDate}}</div>
          </div>
          <div class="d_f tdc_c_detail_out">
            <div class="text more" ref="more">
              <!--占位-->
            </div>
            <div class="tdc_c_detail" :class="{'retract': al.status}" :style="{'max-height':al.status ? textHeight: ''}" ref="textContainer"> {{al.content}}</div>
          </div>
          <div class="c_showMore d_f ali_c cur_p" v-if="al.status" @click="al.status = false">
            <span class="c_more_title">展开评价详情</span>
            <img src="./img/c_more.png" alt="">
          </div>
          <div class="c_showMore d_f ali_c cur_p" v-if="al.status === false" @click="al.status = true">
            <span class="c_more_title">收起评价详情</span>
            <img src="./img/c_less.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { NavBar, Icon, Rate} from 'vant'
  export default {
    name: "commentList",
    components:{
      'van-nav-bar': NavBar,
      'van-icon': Icon,
      'van-rate': Rate,
    },
    data() {
      return {
        rateValue:3.5,
        textHeight: null,
        apis:{
          slicedQueryUserCriticism: '/userCriticism/slicedQueryUserCriticism',//评论
        },
        bizId:this.$route.query.bizId,
        als:[]
      }
    },
    mounted(){
      this.getComment();
      this.als.forEach((ele, index) => {
        this.als[index] = JSON.parse(JSON.stringify(Object.assign({}, ele, { status: null })));
      })
      // DOM 加载完执行
      this.$nextTick(() => {
        this.calculateText()

      });

      window.onresize = () => {
        this.als.forEach((ele, index) => {
          this.als[index] = JSON.parse(JSON.stringify(Object.assign({}, ele, { status: null })));
        })
        setTimeout(() => {
          this.calculateText()
        }, 0)
      }
    },
    created(){

    },
    methods: {
      //获取评论
      getComment(){
        let me = this;
        me.$post({
          url:me.apis.slicedQueryUserCriticism,
          params: {
            bizId: me.bizId,
            bizType: 3,
            token: me.$global.token
          }
        }).then(res => {//thirdName
          me.als = res.data.data;
        });
      },
      calculateText() {
        // 获取一行文字的height 计算当前文字比较列表文字
        let oneHeight = this.$refs.more.scrollHeight
        let twoHeight = oneHeight * 3 || 60
        this.textHeight = `${twoHeight}px`
        let txtDom = this.$refs.textContainer
        for (let i = 0; i < txtDom.length; i++) {
          let curHeight = txtDom[i].offsetHeight
          if (curHeight > twoHeight) {
            this.als[index] = JSON.parse(JSON.stringify(Object.assign({}, this.als[i], { status: null })));
          } else {
            this.als[index] = JSON.parse(JSON.stringify(Object.assign({}, this.als[i], { status: null })));
          }
        }
      },
      exit(){
        sessionStorage.clear();
        sessionStorage.setItem('LOGIN_STATUS',2);
        sessionStorage.setItem('token',this.$global.token);
        this.$router.go(-1)
      }
    }
  }
</script>

<style scoped lang="scss">

  .text {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #666666;
  }

  .retract {
    position: relative;
    overflow: hidden;
  }

  .retract:after {
    content: '...';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25px;
    padding-left: 30px;
    background: linear-gradient(to right, transparent, #fff 45%);
  }
  .btn {
    position: absolute;
    right: 0;
    bottom: -30px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #FFAD41;
    cursor: pointer;
  }

  .more {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #666666;
    visibility: hidden;
  }
  .tdc_content{
    .c_showMore{
      img{
        width: 10px;
        margin-left: 4px;
      }
      margin-top: 9px;
    }
    .c_more_title{
      font-size:12px;

      font-weight:500;
      color:rgba(62,211,103,1);
    }
    padding: 9px 20px;
    border-bottom: 1px solid #E6E6E6;
    .tdc_c{
      .tdc_c_detail_out{
        margin-top: 13px;
      }
      .tdc_c_detail{
        font-size:14px;

        font-weight:400;
        color:rgba(87,87,87,1);
        line-height:20px;
      }
      .tdc_c_date{
        font-size:10px;

        font-weight:500;
        color:rgba(87,87,87,1);
      }
      .tdc_c_title{
        font-size:14px;

        font-weight:500;
        color:rgba(20,18,18,1);
        /*margin-bottom: 7px;*/
      }
    }
    .tdc_user{
      img{
        width: 100%;
      }
      margin-right: 6px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .intro .content{
    padding: 46px 0 30px 0;
  }
</style>
